html {
  scroll-behavior: smooth;
  font-size: 16px;
  text-rendering: optimizeLegibility;
}

section {
  content-visibility: auto;
  contain-intrinsic-size: 0 450px;
}

a {
  padding: 0.2em;
}

p {
  font-size: 1rem;
}

.grIcon > * {
  stroke: inherit;
}

@media (any-pointer: coarse) {
  a {
    padding: 0.8em;
  }
}
