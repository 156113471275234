.row {
  flex-direction: row;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.hide {
  width: 0px;
  height: 0px;
  border-width: 0px;
  padding: 0px !important;
}

.hidden {
  display: none;
}

.mainFixed {
  position: fixed;
  width: 100%;
  overflow-x: hidden;
}

.fixedFrame {
  position: fixed;
  display: contents;
}

.mainFrame {
  width: 100%;
  border-width: 0px;
  overflow-x: hidden;
}

.mainFrameHeight {
  height: calc(100vh - 64px);
}

.mainInput {
  padding: 10px;
  flex: 1;
  display: flex;
  border-radius: 2px;
  border-width: 1px;
  border-color: #6200ee;
}

@media only screen and (max-width: 600px) {
  .main {
    position: relative;
  }
}
